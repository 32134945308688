import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import {Box, Button, CircularProgress, Slider, Typography} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {ImageGalleryEvents} from "../services/analytics/events";
import {trackEvent} from "../services/analytics/fathom";
import {useTracking} from "../hooks/useTracking";
import {getMaxDurationForPlatforms, getMinDurationForPlatforms} from "../utils/platformLimits";

const VideoEditor = ({
    onVideoProcess,
    childId,
    initialVideoUrl,
    onError,
    isProcessing: externalIsProcessing, 
    setIsProcessing: externalSetIsProcessing,
    setVideoProcessed,
    selectedPlatforms = [],
    creatorInfo
}) => {
    const [video, setVideo] = useState(null);
    const [processedVideoUrl, setProcessedVideoUrl] = useState(initialVideoUrl || null);
    const [duration, setDuration] = useState(0);
    const [trim, setTrim] = useState([0, 0]);
    const [playing, setPlaying] = useState(false);
    const playerRef = useRef(null);
    const [localIsProcessing, setLocalIsProcessing] = useState(false);
    const isProcessing = externalIsProcessing ?? localIsProcessing;
    const setIsProcessing = externalSetIsProcessing ?? setLocalIsProcessing;

    // Add new state for trim validation
    const [isTrimValid, setIsTrimValid] = useState(true);
    const [trimError, setTrimError] = useState('');

    const videoUrl = useMemo(() => {
        if (processedVideoUrl) return processedVideoUrl;
        if (video) return URL.createObjectURL(video);
        return null;
    }, [video, processedVideoUrl]);

    const maxAllowedDuration = useMemo(() => {
        const platformMax = getMaxDurationForPlatforms(selectedPlatforms);
        const tiktokMax = creatorInfo?.max_video_post_duration_sec || 60;
        
        return Math.min(platformMax, tiktokMax);
    }, [selectedPlatforms, creatorInfo]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        
        // Create temporary URL to check video duration
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        
        videoElement.onloadedmetadata = () => {
            URL.revokeObjectURL(videoElement.src);
            const duration = videoElement.duration;
            const minDuration = getMinDurationForPlatforms();
            const maxDuration = maxAllowedDuration;
            
            if (duration < getMinDurationForPlatforms()) {
                setTrimError(`Trimmed video must be at least ${minDuration} seconds long`);
                // return;
            }
            
            if (duration > maxAllowedDuration) {
                setTrimError(`Video exceeds maximum duration of ${maxAllowedDuration} seconds for selected platforms`);
                // return;
            }
            
            setVideo(file);
            setProcessedVideoUrl(null);
        };
        
        videoElement.src = URL.createObjectURL(file);
    };

    const handleDuration = (duration) => {
        setDuration(duration);
        setTrim([0, duration]);
    };

    // Add validation function for trim duration
    const validateTrimDuration = useCallback((start, end) => {
        const trimDuration = end - start;
        const minDuration = getMinDurationForPlatforms();
        
        if (trimDuration < minDuration) {
            setTrimError(`Trimmed video must be at least ${minDuration} seconds long`);
            setIsTrimValid(false);
            return;
        }
        
        if (trimDuration > maxAllowedDuration) {
            setTrimError(`Trimmed video cannot exceed ${maxAllowedDuration} seconds for selected platforms`);
            setIsTrimValid(false);
            return;
        }
        
        setTrimError('');
        setIsTrimValid(true);
    }, [maxAllowedDuration]);

    // Modify handleTrimChange to include validation
    const handleTrimChange = (event, newValue) => {
        setTrim(newValue);
        validateTrimDuration(newValue[0], newValue[1]);
        if (playerRef.current) {
            playerRef.current.seekTo(newValue[0], 'seconds');
        }
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleProgress = useCallback(({playedSeconds}) => {
        if (playedSeconds >= trim[1]) {
            setPlaying(false);
            playerRef.current.seekTo(trim[0], 'seconds');
        }
    }, [trim]);

    const handleVideoProcess = async () => {
        setIsProcessing(true);
        const formData = new FormData();
        formData.append('video', video);
        formData.append('start', trim[0]);
        formData.append('end', trim[1]);

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'templates/create/video/', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                trackEvent(ImageGalleryEvents.PROCESSING_VIDEO_CLIP_ERROR.name);
                throw new Error(data.error || 'Video processing failed');

            }

            setProcessedVideoUrl(data.url);
            trackEvent(ImageGalleryEvents.PROCESSING_VIDEO_CLIP_SUCCESS.name)
            onVideoProcess(childId, data.url, data.duration);
            setVideoProcessed(true)
        } catch (error) {
            console.error('Error processing video:', error);
            trackEvent(ImageGalleryEvents.PROCESSING_VIDEO_CLIP_ERROR.name);
            onError(error.message || 'Failed to process video');
            setProcessedVideoUrl(null);
        } finally {
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        // Reset player when video changes
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }
    }, [videoUrl]);

    // Cleanup function to revoke object URL
    useEffect(() => {
        return () => {
            if (video) URL.revokeObjectURL(URL.createObjectURL(video));
        };
    }, [video]);

    return (
        <Box>
            <input
                accept="video/*"
                style={{display: 'none'}}
                id="raised-button-file"
                type="file"
                onChange={handleFileUpload}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained"
                        onClick={() => {
                            trackEvent(ImageGalleryEvents.UPLOAD_VIDEO_BTN_CLICKED.name);
                        }}
                        component="span"
                        startIcon={<CloudUploadIcon/>}>
                    Upload Video
                </Button>
            </label>
            {videoUrl && (
                <>
                    <Box my={2} position="relative">
                        <ReactPlayer
                            ref={playerRef}
                            url={videoUrl}
                            controls
                            width="30%"
                            height="auto"
                            onDuration={handleDuration}
                            playing={playing}
                            onProgress={handleProgress}
                        />
                        {isProcessing && (
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bgcolor="rgba(0,0,0,0.5)"
                            >
                                <CircularProgress/>
                            </Box>
                        )}
                    </Box>
                    <Box my={2}>
                        <Typography gutterBottom>Trim Video</Typography>
                        <Slider
                            value={trim}
                            onChange={handleTrimChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={duration}
                            step={0.1}
                            disabled={isProcessing}
                        />
                        <Box display="flex" justifyContent="space-between">
                            <Typography>Start: {trim[0].toFixed(2)}s</Typography>
                            <Typography>End: {trim[1].toFixed(2)}s</Typography>
                        </Box>
                        {trimError && (
                            <Typography color="error" variant="caption">
                                {trimError}
                            </Typography>
                        )}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            trackEvent(ImageGalleryEvents.PREVIEW_VIDEO_BTN_CLICKED.name);
                            handlePlayPause()
                        }}
                        startIcon={playing ? <PauseIcon/> : <PlayArrowIcon/>}
                        disabled={isProcessing}

                    >
                        {playing ? 'Pause' : 'Preview Clip'}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleVideoProcess}
                        sx={{ml: 2}}
                        disabled={isProcessing || processedVideoUrl !== null || !isTrimValid}
                    >
                        {isProcessing ? <CircularProgress size={24}/> : 'Process Video'}
                    </Button>
                    {/*{maxAllowedDuration < Infinity && (*/}
                    {/*    <Typography variant="caption" color="textSecondary">*/}
                    {/*        Maximum video duration for selected platforms: {maxAllowedDuration} seconds*/}
                    {/*    </Typography>*/}
                    {/*)}*/}
                </>
            )}
        </Box>
    );
};

export default VideoEditor;