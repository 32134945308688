import React from "react";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useAuth } from "../providers/AuthProvider";
import Paper from "@mui/material/Paper";
import TwitterConnectButton from "./TwitterConnectButton";
import Button from "@mui/material/Button";
import LinkedInConnectButton from "./LinkedInConnectButton";
import InstagramConnectButton from "./InstagramConnectButton";
import BlueSkyConnectButton from "./BlueSkyConnectButton";
import TikTokConnectButton from "./TikTokConnectButton";
import LinkedInOrgConnectButton from "./LinkedInOrgConnectButton";
import Box from "@mui/material/Box";
import ThreadsConnectButton from "./ThreadsConnectButton";
import MastodonConnectButton from "./MastodonConnectButton";
import FacebookConnectButton from "./FacebookConnectButton";

export default function SocialAccountsDisplay({ filtered_account, onAccountRemoved }) {
    const auth = useAuth();
    const totalConnectedAccounts = auth.user[0]?.userprofile.social_accounts.length || 0;
    const maxAllowedAccounts = auth.user[0]?.userprofile.subscription_plan?.max_social_accounts || 0;
    const hasReachedLimit = totalConnectedAccounts >= maxAllowedAccounts;

    const connectedAccounts = auth.user[0]?.userprofile.social_accounts.filter(
        account => account.platform === filtered_account
    );

    const handleRemoveAccount = async (accountId) => {
        try {
            const url = process.env.REACT_APP_API_BASE_URL + `connect/remove`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${auth.token}`
                },
                body: JSON.stringify({
                    social_account_id: accountId,
                })
            });
            
            if (response.ok) {
                onAccountRemoved();
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const renderConnectButton = () => {
        if (hasReachedLimit) return null;

        switch(filtered_account) {
            case 'twitter':
                return <TwitterConnectButton />;
            case 'linkedin':
                return <LinkedInConnectButton />;
            case 'linkedin_org':
                return <LinkedInOrgConnectButton />;
            case 'instagram':
                return <InstagramConnectButton />;
            case 'threads':
                return <ThreadsConnectButton />;
            case 'bluesky':
                return <BlueSkyConnectButton />;
            case 'tiktok':
                return <TikTokConnectButton />;
            case 'mastodon':
                return <MastodonConnectButton />;
            case 'facebook':
                return <FacebookConnectButton />;
            default:
                return null;
        }
    };

    const renderLimitMessage = () => {
        if (hasReachedLimit) {
            return (
                <Paper
                    elevation={2}
                    sx={{
                        p: 2,
                        mb: 2,
                        backgroundColor: 'warning.light',
                        color: 'warning.dark'
                    }}
                >
                    You've reached the maximum number of social accounts ({maxAllowedAccounts})
                    allowed in your subscription plan. To connect more accounts, please either
                    disconnect an existing account or upgrade your plan.
                </Paper>
            );
        }
        return null;
    };

    if (!connectedAccounts?.length) {
        return (
            <Box>
                <Paper
                    elevation={2}
                    sx={{ p: 2, mb: 2 }}
                    variant="outlined"
                >
                    You haven't connected to {filtered_account} yet.
                    {!hasReachedLimit && " Click the button below to connect your account."}
                </Paper>
                {renderLimitMessage()}
                {renderConnectButton()}
            </Box>
        );
    }

    return (
        <Box>
            {renderLimitMessage()}
            {connectedAccounts.map(account => (
                <Box
                    key={account.user_id}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'stretch', sm: 'center' },
                        gap: { xs: 1, sm: 2 },
                        mb: 2,
                        p: { xs: 1.5, sm: 2 },
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: { xs: 1, sm: 0 }
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: 'auto' }}>
                            <Avatar
                                alt={account.username}
                                src={account.account_img}
                                sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
                            />
                        </ListItemIcon>
                        <Box sx={{ 
                            flexGrow: 1,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            wordBreak: 'break-word'
                        }}>
                            {account.username}
                        </Box>
                    </Box>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleRemoveAccount(account.id)}
                        color="error"
                        sx={{
                            alignSelf: { xs: 'stretch', sm: 'center' },
                            mt: { xs: 1, sm: 0 }
                        }}
                    >
                        Disconnect
                    </Button>
                </Box>
            ))}
            {renderConnectButton()}
        </Box>
    );
}