// Add constant for default TikTok duration
export const DEFAULT_TIKTOK_DURATION = 60;

export const VIDEO_DURATION_LIMITS = {
  LINKEDIN: {
    MIN: 3,
    MAX: 600 // 10 minutes in seconds
  },
  FACEBOOK: {
    MIN: 3,
    MAX: 1200 // 20 minutes in seconds
  },
  BLUESKY: {
    MIN: 3,
    MAX: 60
  },
  INSTAGRAM_REELS: {
    MIN: 3,
    MAX: 90
  },
  INSTAGRAM_STORIES: {
    MIN: 3,
    MAX: 60
  },
  TIKTOK: {
    MIN: 3,
    MAX: null // Will be dynamically set based on creator info
  }
};

export const getMaxDurationForPlatforms = (platforms = [], tiktokCreatorInfo = null) => {
  if (!platforms || platforms.length === 0) {
    return Infinity;
  }
  
  const durations = platforms.map(platform => {
    const platformKey = platform?.toUpperCase();
    const limits = VIDEO_DURATION_LIMITS[platformKey];
    
    if (platformKey === 'TIKTOK') {
      // Use creator info max duration if available, otherwise use default
      return tiktokCreatorInfo?.max_video_post_duration_sec || DEFAULT_TIKTOK_DURATION;
    }
    
    return limits ? limits.MAX : Infinity;
  });
  
  return durations.length > 0 ? Math.min(...durations) : Infinity;
};

export const getMinDurationForPlatforms = () => {
  return Math.max(...Object.values(VIDEO_DURATION_LIMITS).map(limit => limit.MIN));
};