const API_BASE_URL = 'https://api.staging.onsolosocial.com';

export const getPricingPlans = async () => {
  const response = await fetch(API_BASE_URL+ "user/pricing-plans/");
  return response.json();
};

export const postToTikTok = async (postData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/social/post/tiktok/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        video_url: postData.videoUrl,
        caption: postData.title,
        post_metadata: {
          social_account_id: postData.socialAccountId,
          privacy_level: postData.privacyLevel,
          allow_comment: postData.allowComment,
          allow_duet: postData.allowDuet,
          allow_stitch: postData.allowStitch,
          is_commercial_content: postData.isCommercialContent,
          is_branded_content: postData.isBrandedContent,
          is_your_brand: postData.isYourBrand
        }
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to post to TikTok');
    }

    const data = await response.json();
    return {
      success: data.success,
      postId: data.post_id,
      postUrl: data.post_url,
      message: data.message
    };
  } catch (error) {
    console.error('Error posting to TikTok:', error);
    throw error;
  }
};

export const getTikTokCreatorInfo = async (socialAccountId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}connect/tiktok/creator-info/${socialAccountId}`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch creator info');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching TikTok creator info:', error);
    throw error;
  }
};

export const checkTikTokPostStatus = async (postId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/social/post/tiktok/status/${postId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to check TikTok post status');
    }

    const data = await response.json();
    return {
      status: data.status, // 'PROCESSING', 'PUBLISHED', 'FAILED'
      message: data.message,
      postUrl: data.post_url
    };
  } catch (error) {
    console.error('Error checking TikTok post status:', error);
    throw error;
  }
};