import React, { useState, useEffect, useCallback } from 'react';
import { Box, Alert, CircularProgress, Button } from '@mui/material';
import { TikTokPostForm } from "./TikTokPostForm";
import { postToTikTok, checkTikTokPostStatus } from '../api';
import { useCreatorInfo } from "../hooks/useCreatorInfo";

const POLLING_INTERVAL = 5000; // 5 seconds
const MAX_POLLING_ATTEMPTS = 60; // 5 minutes maximum polling time

const TikTokPostContainer = ({
  videoUrl,
  socialAccountId,
  onPostComplete,
  onError,
  message,
  onMessageChange,
  onFormValidityChange
}) => {
  const [isPosting, setIsPosting] = useState(false);
  const [postId, setPostId] = useState(null);
  const [pollingAttempts, setPollingAttempts] = useState(0);
  const { creatorInfo, isLoading: isLoadingCreatorInfo, error: creatorInfoError, refetch } = useCreatorInfo(socialAccountId);

  // Combine loading states - only consider posting state here
  const isLoading = isPosting;

  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  const checkPostStatus = useCallback(async () => {
    if (!postId) return;

    try {
      const status = await checkTikTokPostStatus(postId);
      
      switch (status.status) {
        case 'PUBLISHED':
          setIsPosting(false);
          setPostId(null);
          onPostComplete({
            success: true,
            message: 'Post published successfully!',
            postUrl: status.postUrl
          });
          break;

        case 'FAILED':
          setIsPosting(false);
          setPostId(null);
          onError(status.message || 'Failed to publish post');
          break;

        case 'PROCESSING':
          if (pollingAttempts >= MAX_POLLING_ATTEMPTS) {
            setIsPosting(false);
            setPostId(null);
            onError('Post processing timeout. Please check TikTok app for status.');
          } else {
            setPollingAttempts(prev => prev + 1);
            setTimeout(checkPostStatus, POLLING_INTERVAL);
          }
          break;

        default:
          setIsPosting(false);
          setPostId(null);
          onError('Unknown post status');
      }
    } catch (error) {
      setIsPosting(false);
      setPostId(null);
      onError(error instanceof Error ? error.message : 'Failed to check post status');
    }
  }, [postId, pollingAttempts, onPostComplete, onError]);

  useEffect(() => {
    if (postId) {
      checkPostStatus();
    }
  }, [postId, checkPostStatus]);

  useEffect(() => {
    if (isPosting) {
      setLoadingMessage('Posting to TikTok...');
    }
  }, [isPosting]);

  // Handle creator info error
  useEffect(() => {
    if (creatorInfoError) {
      onError('Failed to load TikTok account information. Please try again later.');
    }
  }, [creatorInfoError, onError]);

  const handleSubmit = async (formData) => {
    try {
      setIsPosting(true);
      setPollingAttempts(0);
      
      const result = await postToTikTok({
        videoUrl,
        socialAccountId,
        ...formData
      });

      if (result.postId) {
        setPostId(result.postId);
      } else {
        throw new Error('No post ID received from API');
      }
    } catch (error) {
      setIsPosting(false);
      onError(error instanceof Error ? error.message : 'Failed to post to TikTok');
    }
  };

  return (
    <Box>
      {isLoading && (
        <Alert severity="info" sx={{ mb: 2 }}>
          <Box display="flex" alignItems="center" gap={2}>
            <CircularProgress size={20} />
            {loadingMessage}
          </Box>
        </Alert>
      )}

      {creatorInfoError && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={refetch}
            >
              Retry
            </Button>
          }
        >
          Failed to load TikTok posting form. Please try again.
        </Alert>
      )}
      
      <TikTokPostForm
        videoUrl={videoUrl}
        onSubmit={handleSubmit}
        creatorInfo={creatorInfo}
        isLoading={isLoadingCreatorInfo}
        title={message}
        onTitleChange={onMessageChange}
        onFormValidityChange={onFormValidityChange}
      />
    </Box>
  );
};

export default TikTokPostContainer;
