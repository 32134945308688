import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import { logError } from "../utils/logger";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [user, setUser] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserDetails = useCallback(async () => {
    if (!localStorage.getItem('token')) {
      setIsLoading(false);
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}user/details/`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`
        },
      });

      const data = await response.json();

      if (data.detail === 'Invalid token.') {
        logout();
        return;
      }

      if (data && data.user_info) {
        setUser([data.user_info]);
      } else {
        setErrorMessage(data[0]);
        logError('Failed to fetch user details', {
          error: data,
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      logError('Error fetching user details', {
        error: error.message,
        timestamp: new Date().toISOString()
      });
      setErrorMessage(['Failed to fetch user details']);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Initial user details fetch
  useEffect(() => {
    fetchUserDetails();
  }, [token, fetchUserDetails]);

  const refreshUser = useCallback(async () => {
    setIsLoading(true);
    await fetchUserDetails();
  }, [fetchUserDetails]);

  const login = useCallback((username, password, timezone) => {

      const url = process.env.REACT_APP_API_BASE_URL + 'user/login/';

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email":username, username, password, timezone }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          // Call the setToken function to store the JWT
          localStorage.setItem('token', data.token);
          setToken(data.token);
          setIsAuthenticated(true);
          setUser([data.user_info]);
          // Redirect the user to the home page
        } else {
          setErrorMessage(data);
        }
      })
        .catch((error) => {
        // set the error message here

            setErrorMessage(error);
      });
  },[]);

  const logout = useCallback(() => {

      const url = process.env.REACT_APP_API_BASE_URL + 'logout/';

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then((response) => {
        if (!response.data) {
          // Call the setToken function to store the JWT
            setIsAuthenticated(false);
            setUser([]);
            setToken('');
            localStorage.removeItem('token');
        } else {
          setErrorMessage(response.data);
          console.error(response.data);
        }
      });

  },[token]);

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      token, 
      login, 
      logout, 
      user, 
      errorMessage, 
      isLoading, 
      setErrorMessage, 
      setToken,
      refreshUser 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
