import { VIDEO_DURATION_LIMITS, getMaxDurationForPlatforms, getMinDurationForPlatforms } from './platformLimits';

/**
 * Validates video duration against platform limits
 * @param {File} file - The video file to validate
 * @param {Array<string>} platforms - Array of selected platform names
 * @param {Object} tiktokCreatorInfo - TikTok creator information
 * @returns {Promise<{isValid: boolean, error: string|null, duration: number}>}
 */
export const validateVideoDuration = async (file, platforms, tiktokCreatorInfo = null) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      const duration = video.duration;
      const maxDuration = getMaxDurationForPlatforms(platforms, tiktokCreatorInfo);
      const minDuration = getMinDurationForPlatforms();

      if (duration < minDuration) {
        resolve({
          isValid: false,
          error: `Video must be at least ${minDuration} seconds long`,
          duration
        });
      } else if (duration > maxDuration) {
        resolve({
          isValid: false,
          error: `Video cannot be longer than ${maxDuration} seconds`,
          duration
        });
      } else {
        resolve({
          isValid: true,
          duration
        });
      }
    };

    video.src = URL.createObjectURL(file);
  });
};
