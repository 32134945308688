import { useState, useEffect } from 'react';
import { getTikTokCreatorInfo } from '../api';

export const useCreatorInfo = (socialAccountId) => {
  const [creatorInfo, setCreatorInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCreatorInfo = async () => {
      if (!socialAccountId) {
        setIsLoading(false);
        setError('No TikTok account ID provided');
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const response = await getTikTokCreatorInfo(socialAccountId);
        
        if (response.success && response.data) {
          setCreatorInfo(response.data);
        } else {
          throw new Error(response.message || 'Failed to fetch creator info');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch creator info');
        setCreatorInfo(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreatorInfo();
  }, [socialAccountId]);

  return {
    creatorInfo,
    isLoading,
    error,
    refetch: async () => {
      setIsLoading(true);
      try {
        const response = await getTikTokCreatorInfo(socialAccountId);
        if (response.success && response.data) {
          setCreatorInfo(response.data);
          setError(null);
        } else {
          throw new Error(response.message || 'Failed to fetch creator info');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch creator info');
        setCreatorInfo(null);
      } finally {
        setIsLoading(false);
      }
    }
  };
};